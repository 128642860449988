import {navigate} from 'gatsby-link';
import PropTypes from 'prop-types';
import React, {Fragment, useRef, useState} from 'react';
import Typography from '@material-ui/core/Typography';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Mail from '@material-ui/icons/MailOutlined';
import Button from '@material-ui/core/Button';
import styles from './UseCases.module.scss';
import headerStyles from '../Header/Header.module.scss';
import {USE_CASES_BANNER_TEXT, USE_CASES_BUTTON_TEXT} from '../../constants/use-cases';

const UseCases = ({bannerText = USE_CASES_BANNER_TEXT, buttonText = USE_CASES_BUTTON_TEXT, content, images}) => {
  const useCasesWrapperRef = useRef();
  const useCasesItemWidth = 460;
  const [isUseCasesScrolledToLeft, setIsUseCasesScrolledToLeft] = useState(true);
  const [isUseCasesScrolledToRight, setIsUseCasesScrolledToRight] = useState(false);

  const updateScrollControlsState = () => {
    setTimeout(() => {
      const {current: {offsetWidth, scrollLeft, scrollWidth}} = useCasesWrapperRef;
      setIsUseCasesScrolledToLeft(scrollLeft === 0);
      setIsUseCasesScrolledToRight(scrollLeft + offsetWidth === scrollWidth);
    }, 500);
  };

  const scrollUseCasesLeft = () => {
    useCasesWrapperRef.current.scrollBy({
      behavior: 'smooth',
      left: -1 * useCasesItemWidth
    });
    updateScrollControlsState();
  };

  const scrollUseCasesRight = () => {
    useCasesWrapperRef.current.scrollBy({
      behavior: 'smooth',
      left: useCasesItemWidth
    });
    updateScrollControlsState();
  };

  return <Fragment>
    <div className={styles['use-cases-scroll-wrapper']}>
      {!isUseCasesScrolledToLeft && <Fragment>
        <div className={styles['use-cases-overlay-left']} />
        <Button
          className={styles['use-cases-scroll-left']}
          color="primary"
          onClick={scrollUseCasesLeft}
          variant="contained"
        >
          <ArrowBack />
        </Button>
      </Fragment>}
      {!isUseCasesScrolledToRight && <Fragment>
        <div className={styles['use-cases-overlay-right']} />
        <Button
          className={styles['use-cases-scroll-right']}
          color="primary"
          onClick={scrollUseCasesRight}
          variant="contained"
        >
          <ArrowForward />
        </Button>
      </Fragment>}
      <div className={styles['use-cases-wrapper']} ref={useCasesWrapperRef}>
        <div className={styles['use-cases-container']}>
          {content.map(({image, text, title}) => {
            return <div className={styles['use-cases-item']} key={image}>
              <img src={images[image]} />
              <Typography className={styles['use-cases-item-heading']} color="secondary" variant="h3">
                {title}
              </Typography>
              <div>{text}</div>
            </div>;
          })}
        </div>
      </div>
    </div>
    <div className={styles['use-cases-banner']}>
      <div className={`${styles['use-cases-banner-text']} ${styles['use-cases-banner-text-desktop']}`}>
        {bannerText.join(' ')}
      </div>
      <div className={`${styles['use-cases-banner-text']} ${styles['use-cases-banner-text-mobile']}`}>
        {bannerText.map((item, index) => <div key={`use-cases-banner-text-${index}`}>{item}</div>)}
      </div>
      <Button
        classes={{root: `${headerStyles['header__contact-us']} ${headerStyles['no-padding-media-query']}`}}
        color="primary"
        onClick={() => navigate('/contact-us')}
        startIcon={<Mail />}
        variant="contained"
      >
        {buttonText}
      </Button>
    </div>
  </Fragment>;
};

UseCases.propTypes = {
  bannerText: PropTypes.array,
  content: PropTypes.array.isRequired,
  images: PropTypes.object.isRequired
};

export default UseCases;
